import React, { useState } from 'react';
import onClickOutside from 'react-onclickoutside';

function Category({ category, setCategory }) {
  const [isOpen, setOpen] = useState(false);
  const categories = [
    'musik',
    'theater',
    'sport',
    'comedy',
    'festival',
    'familie',
  ];

  const handleClear = () => {
    setCategory(null);
    setOpen(false);
  };

  Category.handleClickOutside = () => setOpen(false);

  return (
    <div
      className={`category-selector ${isOpen ? 'open' : ''}`}
      role="list-box"
    >
      <div
        className="category-selector__content"
        onClick={() => setOpen(!isOpen)}
      >
        {category ? (
          <>
            <span className="category-selector--selected">{category}</span>
            <button
              className="category-selector-clear"
              onClick={() => handleClear()}
            ></button>
          </>
        ) : (
          <span>Events in selected category</span>
        )}
        {isOpen && (
          <div className="category-selector__dropdown">
            {categories.map((event, key) => (
              <div
                key={`${key}-${event}`}
                className="category-option"
                role="option"
                onClick={() => setCategory(event)}
              >
                {event}
              </div>
            ))}
          </div>
        )}
      </div>
      {category && (
        <button
          className="category-selector-clear"
          onClick={() => handleClear()}
        ></button>
      )}
    </div>
  );
}

const clickOutsideConfig = {
  handleClickOutside: () => Category.handleClickOutside,
};

export default onClickOutside(Category, clickOutsideConfig);
