export default function dateFormat(date) {
  // FORMAT: YYYY-MM-DDTHH:mm:ssZ
  // example: 2020-08-01T14:00:00Z
  const year = date.getFullYear();
  let month = date.getMonth() + 1;
  let day = date.getDate();

  if (String(month).length === 1) {
    month = '0' + month;
  }

  if (String(day).length === 1) {
    day = '0' + day;
  }

  return `${year}-${month}-${day}T00:00:00Z`;
}

export const displayMonth = (month) => {
  switch (month) {
    case '01':
      return 'jan';
    case '02':
      return 'feb';
    case '03':
      return 'mar';
    case '04':
      return 'apr';
    case '05':
      return 'may';
    case '06':
      return 'jun';
    case '07':
      return 'jul';
    case '08':
      return 'aug';
    case '09':
      return 'sep';
    case '10':
      return 'oct';
    case '11':
      return 'nov';
    case '12':
      return 'dec';
    default:
      return month;
  }
};
