import React from 'react';

function Skeleton({ active }) {
  if (!active) return;

  return (
    <div className="skeleton">
      <label>Event</label>
      <div className="skeleton__field"></div>
      <label>Startdato</label>
      <div className="skeleton__field"></div>
      <label>Slutdato</label>
      <div className="skeleton__field"></div>
      <label>Kategori</label>
      <div className="skeleton__field"></div>
      <label>Postnummer</label>
      <div className="skeleton__field last"></div>
      <div className="skeleton__field button"></div>
    </div>
  );
}

export default Skeleton;
