// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-jsx": () => import("./../../../src/pages/404.jsx" /* webpackChunkName: "component---src-pages-404-jsx" */),
  "component---src-pages-about-jsx": () => import("./../../../src/pages/about.jsx" /* webpackChunkName: "component---src-pages-about-jsx" */),
  "component---src-pages-cities-birmingham-jsx": () => import("./../../../src/pages/cities/birmingham.jsx" /* webpackChunkName: "component---src-pages-cities-birmingham-jsx" */),
  "component---src-pages-cities-glasgow-jsx": () => import("./../../../src/pages/cities/glasgow.jsx" /* webpackChunkName: "component---src-pages-cities-glasgow-jsx" */),
  "component---src-pages-cities-london-jsx": () => import("./../../../src/pages/cities/london.jsx" /* webpackChunkName: "component---src-pages-cities-london-jsx" */),
  "component---src-pages-cities-manchester-jsx": () => import("./../../../src/pages/cities/manchester.jsx" /* webpackChunkName: "component---src-pages-cities-manchester-jsx" */),
  "component---src-pages-cities-newcastle-jsx": () => import("./../../../src/pages/cities/newcastle.jsx" /* webpackChunkName: "component---src-pages-cities-newcastle-jsx" */),
  "component---src-pages-cities-sheffield-jsx": () => import("./../../../src/pages/cities/sheffield.jsx" /* webpackChunkName: "component---src-pages-cities-sheffield-jsx" */),
  "component---src-pages-contact-jsx": () => import("./../../../src/pages/contact.jsx" /* webpackChunkName: "component---src-pages-contact-jsx" */),
  "component---src-pages-cookies-jsx": () => import("./../../../src/pages/cookies.jsx" /* webpackChunkName: "component---src-pages-cookies-jsx" */),
  "component---src-pages-events-jsx": () => import("./../../../src/pages/events.jsx" /* webpackChunkName: "component---src-pages-events-jsx" */),
  "component---src-pages-index-jsx": () => import("./../../../src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-templates-event-jsx": () => import("./../../../src/templates/event.jsx" /* webpackChunkName: "component---src-templates-event-jsx" */)
}

