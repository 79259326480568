export default function handleCategory(category) {
  // Returns genreId
  switch (category) {
    case 'comedy':
      return 'KnvZfZ7vAe1';
    case 'music':
      // Rock, Country, Other, Pop, Alternative, Dance/electronic, Classical, Blues, Jazz, Hip-hop/Rap, Metal, Folk, R&B
      return 'KnvZfZ7vAeA,KnvZfZ7vAv6,KnvZfZ7vAvl,KnvZfZ7vAev,KnvZfZ7vAvv,KnvZfZ7vAvF,KnvZfZ7vAeJ,KnvZfZ7vAvd,KnvZfZ7vAvE,KnvZfZ7vAv1,KnvZfZ7vAvt,KnvZfZ7vAva,KnvZfZ7vAee';
    case 'theatre':
      return 'KnvZfZ7v7l1';
    case 'sport':
      // Handball, Skiing, Tennis, Martial Arts, Equestrian
      return 'KnvZfZ7vAdl,KnvZfZ7vAd6,KnvZfZ7vAAv,KnvZfZ7vA7d,KnvZfZ7vAd1';
    case 'festival':
      return 'KnvZfZ7vAeE';
    case 'family':
      return 'KnvZfZ7vA1n';
    default:
      return null;
  }
}
