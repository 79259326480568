import React from 'react';

function ZipCode({ handleKey, zipCode, setZipCode }) {
  return (
    <div className="zipCode-selector">
      <input
        onKeyPress={handleKey && ((e) => handleKey(e))}
        className="category-field"
        placeholder="Events in postal"
        value={zipCode}
        onChange={(e) => setZipCode(e.target.value)}
      />
      {zipCode && (
        <button
          className="zipCode-selector-clear"
          onClick={() => setZipCode('')}
        ></button>
      )}
    </div>
  );
}

export default ZipCode;
