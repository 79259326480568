import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import DatePicker, { registerLocale } from 'react-datepicker';
import { enGB } from 'date-fns/esm/locale';

registerLocale('en-GB', enGB);

class CustomInput extends React.Component {
  render() {
    const { value, onClick, className, placeholder } = this.props;
    return (
      <div
        className={`custom-input ${className}${value ? ' date-selected' : ''}`}
        onClick={onClick}
      >
        {value ? value : <span>{placeholder}</span>}
      </div>
    );
  }
}

function Calendar({ startDate, setStartDate, endDate, setEndDate }) {
  useEffect(() => {
    if (startDate > endDate) {
      setEndDate(null);
    }
  }, [startDate]);

  return (
    <>
      <div className="calendar-container">
        <label>From</label>
        <DatePicker
          customInput={<CustomInput />}
          selected={startDate}
          onChange={(date) => setStartDate(date)}
          selectsStart
          startDate={startDate}
          minDate={new Date()}
          calendarClassName="startdate-container"
          value={startDate}
          isClearable
          placeholderText="Events starts from"
          locale="en-GB"
          dateFormat="dd. MMMM yyyy"
        />
      </div>
      <div className="calendar-container">
        <label>To</label>
        <DatePicker
          customInput={<CustomInput />}
          selected={endDate}
          onChange={(date) => setEndDate(date)}
          selectsEnd
          endDate={endDate}
          minDate={startDate || new Date()}
          isClearable
          placeholderText="Events ends at"
          calendarClassName="enddate-container"
          value={endDate}
          locale="en-GB"
          dateFormat="dd. MMMM yyyy"
        />
      </div>
    </>
  );
}

Calendar.defaultProps = {
  startDate: PropTypes.null,
  endDate: PropTypes.null,
};

Calendar.propTypes = {
  startDate: PropTypes.instanceOf(Date),
  endDate: PropTypes.instanceOf(Date),
  setStartDate: PropTypes.func.isRequired,
  setEndDate: PropTypes.func.isRequired,
};

export default Calendar;
