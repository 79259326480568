import React, { useState, useEffect, useContext } from 'react';
import { Link } from 'gatsby';
import Calendar from '../Calendar';
import Category from './category';
import ZipCode from './zipCode';
import Keyword from './keyword';
import Skeleton from '../Skeleton';
import useSearch from '../../hooks/useSearch';
import useWindowSize from '../../hooks/useWindowSize';
import { EventsContext } from '../../provider/EventProvider';
import throttle from '../../utils/throttle';
import Logo from '../../images/zooticket.svg';
import { SearchContext } from '../../provider/SearchProvider';

function Sidebar({ delay }) {
  const { state } = useContext(EventsContext);
  const {
    keyword,
    setKeyword,
    startDate,
    setStartDate,
    endDate,
    setEndDate,
    category,
    setCategory,
    zipCode,
    setZipCode,
  } = useContext(SearchContext);
  const [isVisible, setVisible] = useState(true);
  const [isRendering, setRendering] = useState(true);
  const [handleClick, , resetSearch] = useSearch(
    keyword,
    startDate,
    endDate,
    zipCode,
    category
  );
  const [navOpen, setOpen] = useState(false);
  const [windowWidth] = useWindowSize();

  const handleReset = () => {
    if (state.isSearching || delay) return;
    setKeyword('');
    setStartDate(null);
    setEndDate(null);
    setOpen(false);
    setZipCode('');
    setCategory(null);

    resetSearch();
  };

  let latest = 0;
  const showHeader = () => {
    if (window.scrollY < 80) {
      setVisible(true);
      return;
    }

    if (latest > window.scrollY) {
      setVisible(true);
    } else {
      setVisible(false);
    }

    latest = window.scrollY;
  };

  const handleClicking = () => {
    handleClick();
    // Close menu on phone
    setOpen(false);
  };

  const handleKey = (e) => {
    if (e && e.key === 'Enter') {
      e.preventDefault();
      e.target.blur();
      handleClicking();
    }
  };

  const toggleSidebar = () => {
    const menu = document.querySelector('.sidebar');
    if (navOpen || state.isSearching || delay) return;

    if (isVisible) {
      menu.classList.remove('is-hidden');
    } else {
      menu.classList.add('is-hidden');
    }
  };

  useEffect(() => {
    if (windowWidth === 0 || windowWidth > 1024) return;

    document.addEventListener(
      'scroll',
      throttle(() => showHeader(windowWidth), 200)
    );
    return () =>
      document.removeEventListener('scroll', throttle(showHeader(windowWidth)));
  }, [windowWidth]);

  useEffect(() => {
    if (windowWidth === 0 || windowWidth > 1024) return;
    toggleSidebar();
  }, [isVisible, navOpen, state.isSearching, delay]);

  useEffect(() => {
    if (state.isSearching) setOpen(false);
  }, [state]);

  useEffect(() => {
    setRendering(false);
  }, []);

  return (
    <>
      <nav className={`sidebar${windowWidth <= 1024 ? ' mobile' : ''}`}>
        <div className="sidebar__header">
          <Link
            onClick={() => handleReset()}
            style={{ height: 40 }}
            className="sidebar__header-link"
            to="/"
          >
            <img src={Logo} alt="Zooticket logo" />
          </Link>
          {windowWidth <= 1024 && (
            <>
              <span style={{ flex: 1 }} />
              <div
                className={navOpen ? 'Menu__Icon open' : 'Menu__Icon'}
                onClick={() => setOpen(!navOpen)}
                role="presentation"
              >
                <span />
                <span />
                <span />
              </div>
            </>
          )}
        </div>
        {isRendering && <Skeleton active={isRendering} />}
        {windowWidth > 1024 && (
          <div className="sidebar__content">
            <form>
              <label>Event</label>
              <Keyword
                handleKey={handleKey}
                keyword={keyword}
                setKeyword={setKeyword}
              />
              <Calendar
                setStartDate={setStartDate}
                setEndDate={setEndDate}
                startDate={startDate}
                endDate={endDate}
              />
              <label>Category</label>
              <Category category={category} setCategory={setCategory} />
              <label>Postal</label>
              <ZipCode
                handleKey={handleKey}
                zipCode={zipCode}
                setZipCode={setZipCode}
              />
            </form>
            <button onClick={() => handleClick()} className="btn">
              Search
            </button>
          </div>
        )}
      </nav>

      {windowWidth <= 1024 && (
        <div
          className={`sidebar__dropdown${
            navOpen && !state.isSearching ? ' open' : ''
          }`}
        >
          <form className="search-form">
            <label>Event</label>
            <Keyword keyword={keyword} setKeyword={setKeyword} />
            <Calendar
              setStartDate={setStartDate}
              setEndDate={setEndDate}
              startDate={startDate}
              endDate={endDate}
            />
            <label>Category</label>
            <Category category={category} setCategory={setCategory} />
            <label>Postal</label>
            <ZipCode zipCode={zipCode} setZipCode={setZipCode} />
          </form>
          <button onClick={() => handleClicking()} className="btn">
            Search
          </button>
        </div>
      )}
    </>
  );
}

export default Sidebar;
