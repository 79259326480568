function handleCity(city) {
  switch (city) {
    case 'london':
      return 'london';
    case 'birmingham':
      return 'birmingham';
    case 'manchester':
      return 'manchester';
    case 'glasgow':
      return 'glasgow';
    case 'newcastle':
      return 'newcastle';
    case 'sheffield':
      return 'sheffield';
    default:
      return '';
  }
}

export default handleCity;
