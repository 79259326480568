import React, { useReducer } from 'react';
import reducer, { initialState } from '../reducer';

export const EventsContext = React.createContext(initialState);

function EventProvider({ children }) {
  const [state, dispatch] = useReducer(reducer, initialState);
  return (
    <EventsContext.Provider value={{ state, dispatch }}>
      {children}
    </EventsContext.Provider>
  );
}

export default EventProvider;
