import React, { useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Fade } from 'react-reveal';
import { FaSearchengin } from 'react-icons/fa';
import usePrevious from '../../hooks/usePrevious';
import { EventsContext } from '../../provider/EventProvider';

function Searching({ active, delay, setDelay }) {
  const { state, dispatch } = useContext(EventsContext);
  const previousState = usePrevious(active, state.noEvents);
  const [isActive, setActive] = useState(false);

  const handleNoEvents = () => {
    setTimeout(() => {
      // show no events message in 2 sec
      dispatch({
        events: state.events ? state.events : null,
        isSearching: false,
        noEvents: null,
      });
    }, 1500);
  };

  useEffect(() => {
    if (state.noEvents) handleNoEvents();

    if (active) {
      setActive(true);
      setDelay(true);
    }

    if (previousState && previousState.active !== active) {
      setTimeout(() => {
        setActive(false);
        setDelay(false);
      }, 1500);
    }
  }, [active, state.noEvents]);

  if (!isActive || !delay) return null;

  return (
    <div className="searching-layer active">
      {!state.noEvents && (
        <div className="search-icon">
          <div className="search-icon-spinner" />
          <FaSearchengin />
        </div>
      )}
      <Fade bottom cascade>
        <h3>
          {state.noEvents
            ? 'No results'
            : 'Looking for events...'}
        </h3>
      </Fade>
    </div>
  );
}

Searching.defaultProps = {
  active: false,
};

Searching.propTypes = {
  active: PropTypes.bool,
};

export default Searching;
