export const initialState = {
  events: null,
  isSearching: false,
  totalPages: 0,
  totalEvents: 0,
  noEvents: false,
};

/* Use this function, if other calendars than ticketmaster is used
 *
 * const sortByDate = events => {
 *   return events.sort((a, b) => new Date(b.dates.start.localDate) - new Date(a.dates.start.localDate)).reverse()
 */

const reducer = (state, action) => {
  if (action.isSearching) {
    // Is searching
    return { events: state.events, isSearching: true };
  }

  if (!action.events && !action.isSearching && !action.noEvents) {
    // Reset search - clicking on logo
    return { events: null, isSearching: false };
  }

  if (!action.events.length) {
    // No events founded from frontpage
    return { events: null, isSearching: false, noEvents: true };
  }

  if (action.events && action.noEvents) {
    // No events founded but going back to earlier succeded search
    return { events: state.events, isSearching: false, noEvents: true };
  }

  if (action.noEvents === null) {
    // Going back to earlier events, after showing none events message
    return { events: state.events, isSearching: false, noEvents: false };
  }

  const totalPages = action.events[0].page.totalPages;
  const { page } = action.events[0];
  const finalEvents = action.events.map((event) => event._embedded.events);

  return {
    events: finalEvents.flat(),
    isSearching: false,
    totalPages: totalPages,
    totalEvents: page.totalElements,
  };
};

export default reducer;
